import Swiper, {Navigation, Pagination} from "swiper";

Swiper.use([Navigation, Pagination]);

window.addEventListener('DOMContentLoaded', function () {
    var swiper = new Swiper(".mySwiper", {
        slidesPerView: 1,
        spaceBetween: 30,
        centerInsufficientSlides: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            bulletClass: "bg-transparent",
            bulletActiveClass: '!bg-black',
            renderBullet: (index, className) => {
                return '<span class="h-[4px] w-[100px] transform -translate-y-1/3 ' + className + '"></span>'
            },
        },
        breakpoints: {
            // when window width is >= 320px
            992: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
            576: {
                slidesPerView: 2,
                spaceBetween: 30,
            }
        }
    });

    var swiper = new Swiper(".mySwiper2", {
        spaceBetween: 0,
        effect: "fade",
        loop: true,
        //autoHeight: true,
        autoplay: {
            delay: 5000, // changer la vitesse de défilement des slides
        },
    });
    var swiper = new Swiper(".mySwiper3", {
        navigation: {
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev",
        },
    });
    // Récupérer les éléments des flèches du Swiper
    var prevArrow = document.querySelector('.swiper-prev');
    var nextArrow = document.querySelector('.swiper-next');

    var swiper = new Swiper(".formationsSwiper", {
        slidesPerView: 1,
        spaceBetween: 0,
        centerInsufficientSlides: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            bulletClass: "bg-transparent",
            bulletActiveClass: '!bg-black',
            renderBullet: (index, className) => {
                return '<span class="h-[4px] w-[100px] transform -translate-y-1/3 ' + className + '"></span>'
            },
        },
        breakpoints: {
            // when window width is >= 320px
            992: {
                slidesPerView: 5,
                spaceBetween: 30,
            },
            576: {
                slidesPerView: 3,
                spaceBetween: 10,
            }
        }
    });

    var swiper = new Swiper(".teamSwiper", {
        slidesPerView: 1,
        spaceBetween: 0,
        centerInsufficientSlides: true,
        breakpoints: {
            // when window width is >= 320px
            600: {
                slidesPerView: 5,
                spaceBetween: 0,
            },
        }
    });

    var swiper = new Swiper(".prodSwiper", {
        slidesPerView: 1,
        spaceBetween: 0,
        centerInsufficientSlides: true,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            bulletClass: "bg-transparent",
            bulletActiveClass: '!bg-black',
            renderBullet: (index, className) => {
                return '<span class="h-[4px] w-[100px] transform -translate-y-1/3 ' + className + '"></span>'
            },
        },
        breakpoints: {
            // when window width is >= 320px
            992: {
                slidesPerView: 5,
                spaceBetween: 30,
            },
            576: {
                slidesPerView: 3,
                spaceBetween: 10,
            }
        }
    });
})