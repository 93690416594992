window.onload = function () {
	let map;
	let title = 'SLA Make up Academy Valence';
	let zoom = 15;

	let MY_MAPTYPE_ID = 'custom_style';
	let featureOpts = [
		{
			"featureType": "administrative.locality",
			"elementType": "all",
			"stylers": [
				{
					"hue": "#ff0200"
				},
				{
					"saturation": 7
				},
				{
					"lightness": 19
				},
				{
					"visibility": "on"
				}
			]
		},
		{
			"featureType": "administrative.locality",
			"elementType": "labels.text",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"saturation": "-3"
				}
			]
		},
		{
			"featureType": "administrative.locality",
			"elementType": "labels.text.fill",
			"stylers": [
				{
					"color": "#748ca3"
				}
			]
		},
		{
			"featureType": "landscape",
			"elementType": "all",
			"stylers": [
				{
					"hue": "#ff0200"
				},
				{
					"saturation": -100
				},
				{
					"lightness": 100
				},
				{
					"visibility": "simplified"
				}
			]
		},
		{
			"featureType": "poi",
			"elementType": "all",
			"stylers": [
				{
					"hue": "#ff0200"
				},
				{
					"saturation": "23"
				},
				{
					"lightness": "20"
				},
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "poi.school",
			"elementType": "geometry.fill",
			"stylers": [
				{
					"color": "#ffdbda"
				},
				{
					"saturation": "0"
				},
				{
					"visibility": "on"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "geometry",
			"stylers": [
				{
					"hue": "#ff0200"
				},
				{
					"saturation": "100"
				},
				{
					"lightness": 31
				},
				{
					"visibility": "simplified"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "geometry.stroke",
			"stylers": [
				{
					"color": "#f39247"
				},
				{
					"saturation": "0"
				}
			]
		},
		{
			"featureType": "road",
			"elementType": "labels",
			"stylers": [
				{
					"hue": "#008eff"
				},
				{
					"saturation": -93
				},
				{
					"lightness": 31
				},
				{
					"visibility": "on"
				}
			]
		},
		{
			"featureType": "road.arterial",
			"elementType": "geometry.stroke",
			"stylers": [
				{
					"visibility": "on"
				},
				{
					"color": "#ffe5e5"
				},
				{
					"saturation": "0"
				}
			]
		},
		{
			"featureType": "road.arterial",
			"elementType": "labels",
			"stylers": [
				{
					"hue": "#bbc0c4"
				},
				{
					"saturation": -93
				},
				{
					"lightness": -2
				},
				{
					"visibility": "simplified"
				}
			]
		},
		{
			"featureType": "road.arterial",
			"elementType": "labels.text",
			"stylers": [
				{
					"visibility": "off"
				}
			]
		},
		{
			"featureType": "road.local",
			"elementType": "geometry",
			"stylers": [
				{
					"hue": "#ff0200"
				},
				{
					"saturation": -90
				},
				{
					"lightness": -8
				},
				{
					"visibility": "simplified"
				}
			]
		},
		{
			"featureType": "transit",
			"elementType": "all",
			"stylers": [
				{
					"hue": "#e9ebed"
				},
				{
					"saturation": 10
				},
				{
					"lightness": 69
				},
				{
					"visibility": "on"
				}
			]
		},
		{
			"featureType": "water",
			"elementType": "all",
			"stylers": [
				{
					"hue": "#e9ebed"
				},
				{
					"saturation": -78
				},
				{
					"lightness": 67
				},
				{
					"visibility": "simplified"
				}
			]
		}
	];


	/*map = new google.maps.Map(document.getElementById('googlemapvalence'),mapOptions);

	let styledMapOptions = {
		name: 'Custom Style'
	};

	let customMapType = new google.maps.StyledMapType(featureOpts, styledMapOptions);

	map.mapTypes.set(MY_MAPTYPE_ID, customMapType);

	let marker = new google.maps.Marker({
		position: point,
		map: map,
		title: title
	});*/

	let maps = document.querySelectorAll('.gmap');

	maps.forEach(function (item) {
		let id = item.getAttribute('id');
		let lat = parseFloat(item.dataset.lat);
		let lng = parseFloat(item.dataset.lng);

		let point =
			{ lat: lat, lng: lng };
		
		let mapOptions = {
			zoom: zoom,
			center: point,
			mapTypeControlOptions: {
				mapTypeIds: [google.maps.MapTypeId.ROADMAP, MY_MAPTYPE_ID]
			},
			mapTypeId: MY_MAPTYPE_ID,
			streetViewControl: false,
			mapTypeControl: false
		};

		map = new google.maps.Map(document.getElementById(id), mapOptions);

		let styledMapOptions = {
			name: 'Custom Style'
		};

		let customMapType = new google.maps.StyledMapType(featureOpts, styledMapOptions);

		map.mapTypes.set(MY_MAPTYPE_ID, customMapType);

		let marker = new google.maps.Marker({
			position: point,
			map: map,
			title: title
		});
		
	});
};