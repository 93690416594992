let menu = document.getElementById('navbar');
let menuMobile = document.getElementById('menu-mobile-btn');
let closeCross = document.getElementsByClassName('menu-close');
let menuBtn = document.getElementsByClassName('menu-btn');
let menucont = document.getElementById('menu');
let state = false;
let cta = document.getElementById('cta-btn');
let menuscroll = document.getElementById('menuscroll');
let logo = document.getElementById('logo');
let imglogo = logo.querySelector('img');
let nav = menuscroll.querySelector('nav');
let containerDrop = nav.querySelectorAll('ul li div');
let header = document.getElementsByTagName('header');
let body = document.getElementsByTagName('body');


// recherche
let rechdesk = document.getElementById('rech-desk');
let winRech = document.getElementById('rech-open');

document.addEventListener("DOMContentLoaded", function () {

    // Get some elements
    let closeIcon = document.querySelector('.icon-cross');

    window.addEventListener('click', function (e) {
        // Check if target element contains 'subNavbar' or is a child of 'subNavbar' div
        let isChildWin = findClosest(e.target, function (el) {
            return el.classList.contains('rech-open')
        }) !== undefined

        let isIcon = findClosest(e.target, function (el) { //enfant de quelque chose
            return el.hasAttribute('data-te-toggle') && el.classList.contains('search')
        }) !== undefined


        // Get curent event target
        let curentTarget = e.target

        /*
            First check : if user click outside 'subNavbar' div when 'subNavbar' div is active, or if user click on close icon
         */
        if ((!isChildWin && !winRech.classList.contains('hidden') || curentTarget === closeIcon)) {
            winRech.classList.add('hidden');
        } else if (isIcon) {
            winRech.classList.toggle("block");
            winRech.classList.toggle("hidden");
        }
    });

});

function findClosest(element, fn) {
    if (!element) return undefined;
    return fn(element) ? element : findClosest(element.parentElement, fn);
}

window.addEventListener('scroll', function () {
    displayMenu();
});


// menu mebile

window.addEventListener("DOMContentLoaded", () => {
    displayMenu();
    let headerHeight = header[0].offsetHeight;
    menuMobile.onclick = function () {
        if (!state) {
            menu.classList.remove("hidden");
            state = true;
            cta.classList.add("hidden");
            closeCross[0].classList.remove('hidden');
            menuBtn[0].classList.add('hidden');
        } else {
            menu.classList.add('hidden');
            state = false;
            cta.classList.remove("hidden");
            closeCross[0].classList.add('hidden');
            menuBtn[0].classList.remove('hidden');
        }
    };

    if (!(window.location.pathname === '/')) {
        body[0].style.marginTop = (headerHeight - 1) + 'px';
    }

});

// couleur et format du menu au scroll

let displayMenu = function () {
    const scrollPos = window.pageYOffset;
    const scrollValue = window.location.pathname === '/' ? 400 : 80;

    if (scrollPos > scrollValue) {
        menucont.classList.add('bg-black');
        menuscroll.classList.add('md:flex', 'md:flex-row');
        rechdesk.classList.remove('absolute');

        if ((window.location.pathname === '/')) {
            imglogo.classList.remove('md:w-[220px]', 'md:object-contain', '2xl:w-[320px]', 'lg:w-[320px]');
            imglogo.classList.add('md:w-[190px]');
        } else {
            imglogo.classList.remove('md:w-[220px]', 'md:object-contain', '2xl:w-[200px]', 'lg:w-[220px]');
            imglogo.classList.add('md:w-[190px]');

        }

        nav.classList.add('bg-black');
        containerDrop.forEach(oneDrop => {
            oneDrop.classList.add('bg-black');
        });
    } else {
        menucont.classList.remove('bg-black');
        menuscroll.classList.remove('md:flex', 'md:flex-row');
        rechdesk.classList.add('absolute');

        if ((window.location.pathname === '/')) {
            imglogo.classList.add('md:w-[220px]', 'md:object-contain', '2xl:w-[320px]');
            imglogo.classList.remove('md:w-[190px]');
        } else {
            imglogo.classList.add('md:w-[190px]', '2xl:w-[220px]');
            imglogo.classList.remove('md:w-[180px]');

        }

        nav.classList.remove('bg-black');
        containerDrop.forEach(oneDrop => {
            if (window.location.pathname === '/') {
                oneDrop.classList.remove('bg-black');
            }
        });

    }
}

// NAV PILL FORMATIONS
// Récupérer tous les éléments d'onglets avec le data-te-toggle attribut
const toggleTabs = document.querySelectorAll('[data-te-toggle="pill"]');

// Pour chaque élément d'onglet, ajouter un écouteur d'événements de clic
toggleTabs.forEach(tab => {
    tab.addEventListener('click', (e) => {
        e.preventDefault();

        // Récupérer l'élément de contenu correspondant pour l'élément d'onglet actuel
        const target = document.querySelector(tab.getAttribute('href'));
        const activeTab = document.querySelector('[data-te-nav-active]');


        tab.setAttribute('data-te-nav-active', '');
        tab.classList.add('text-[#D50032]', 'relative', 'after:right-1/2', 'after:transform', 'after:translate-x-1/2', 'after:absolute', "after:content-['']", 'after:-bottom-3', 'after:h-1', 'after:w-1/4', 'after:bg-black');

        activeTab.removeAttribute('data-te-nav-active');
        activeTab.classList.remove('text-[#D50032]', 'relative', 'after:right-1/2', 'after:transform', 'after:translate-x-1/2', 'after:absolute', "after:content-['']", 'after:-bottom-3', 'after:h-1', 'after:w-1/4', 'after:bg-black');


        // Récupérer tous les éléments de contenu d'onglet avec le data-te-tab-active attribut
        const activeTabs = document.querySelectorAll('[data-te-tab-active]');

        // Pour chaque élément de contenu d'onglet actif, retirer l'attribut data-te-tab-active
        activeTabs.forEach(activeTab => {
            activeTab.removeAttribute('data-te-tab-active');
            activeTab.classList.remove('opacity-100');
            activeTab.classList.add('opacity-0');
        });


        // Ajouter l'attribut data-te-tab-active et la classe opacity-100 à l'élément de contenu cible
        target.setAttribute('data-te-tab-active', '');
        target.classList.remove('opacity-0');
        target.classList.add('opacity-100');

    });

});


// checkbox
const checkbox = document.querySelectorAll('.formationForm input[type="radio"]');
const checkbox2 = document.querySelectorAll('.location input[type="radio"]');

if (checkbox) {
    checkbox.forEach(oneCheckbox => {
        check(oneCheckbox, checkbox);
    });
}
if (checkbox2) {
    checkbox2.forEach(oneCheckbox => {
        check(oneCheckbox, checkbox2);
    });
}

function check(item, list) {
    item.addEventListener('change', function () {
        list.forEach(item => {
            item.parentElement.querySelector('.cross').classList.add('hidden');
            item.parentElement.querySelector('.cross').classList.remove('flex');
        })
        item.parentElement.querySelector('.cross').classList.remove('hidden');
        item.parentElement.querySelector('.cross').classList.add('flex');
    });
}
